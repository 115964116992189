import React, { ReactElement, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";

import Preloader from "../components/SVG/Preloader";
import { PreloaderContainer } from "../components/Contract/Layout/styled";

const Index = ({ location }: RouteComponentProps): ReactElement | null => {
  const [state, setState] = useState("pending");

  useEffect(() => {
    const redirectToEmailVerification = () => {
      const rx = new RegExp("[?&]token=([^&]+).*$");
      const token = location?.search ? location.search.match(rx) : "";

      if (token?.length) {
        navigate(`/email-verification/${token[1]}`);
        setState("token");
      } else {
        setState("default");
      }
    };

    const redirectToChangeEmail = () => {
      const rx = new RegExp("[?&]token=([^&]+).*$");
      const token = location?.search ? location.search.match(rx) : "";

      if (token?.length) {
        navigate(`/change-email/${token[1]}`);
        setState("token");
      } else {
        setState("default");
      }
    };

    if (URL && location) {
      const params = new URL(location.href).searchParams;
      const action = params.get("action");
      const token = params.get("token");
      const email = params.get("email");

      const passwordRecoveryActions = ["setPassword", "confirmPassword"];

      if (action === "changeEmail") {
        redirectToChangeEmail();
      } else if (
        action &&
        passwordRecoveryActions.includes(action) &&
        token &&
        email
      ) {
        navigate(`/password-recovery/${location.search}`);
        setState("default");
      } else {
        redirectToEmailVerification();
      }
    }
  }, [location]);

  return state === "pending" || state === "token" ? (
    <PreloaderContainer>
      <Preloader />
    </PreloaderContainer>
  ) : (
    <div>
      <a href="https://stromee.de">stromee.de</a>
    </div>
  );
};

export default Index;
